import "../stylus/main.styl";

function toggleFolder(e) {
  e.addEventListener('click', (e) => {
    e.target.classList.toggle('folder-close');
    e.target.parentNode.querySelector('.folder').classList.toggle(
        'folder-open');
  });
}

function clone(e, cl = null) {
  const c = e.cloneNode();
  c.innerHTML = e.innerHTML;
  if (cl) {
    c.classList = cl;
  }
  return c;
}

function openPath(b) {
  b.classList.add('folder-active');
  const main = document.querySelector('.main');
  const bc = document.createElement('DIV');
  bc.classList.add('breadcrumb-container');
  main.prepend(bc);
  while (b.parentNode) {
    const classList = b.classList;
    if (classList) {
      if (classList.contains('folder-root')) {
        break;
      } else if (classList.contains('folder')) {
        classList.add('folder-open');
      } else if (classList.contains('node')) {
        const ft = b.querySelector('.folder-toggle');
        if (ft && b.getElementsByClassName('folder-open').length > 0)
          ft.classList.toggle('folder-close');
        const nl = b.querySelector('.navigation-link');
        if (nl) {
          if (nl.classList.contains('folder-active') && ft) {
            const f = clone(b);
            f.querySelector('.folder').classList.add('folder-open');
            bc.prepend(f);
          } else {
            bc.prepend(clone(nl, 'breadcrumb'));
          }
        }
      }
    }
    b = b.parentNode;
  }
}

function initBranches() {
  const path = window.location.pathname;

  let branch = document.querySelector("a[href='" + path + "']");
  if (path.length > 1 && branch) {
    console.log(path);
    openPath(branch)
  }

  document.querySelectorAll('.folder-toggle')
      .forEach(b => { toggleFolder(b); });
}

function init() { initBranches(); }

window.onload = init;
